import smoothScroll from '../../../client/utils/smoothScroll';
import utilStyles from '../../../styles/global_ui/util.css';

const BUTTON_CLASS = 'challenge-registration-btn';
const DATA_ATTRIB = 'data-reg-status';
const NAV_PLACEHOLDER_CLASS = 'challenge-nav-placeholder';

export function addRegisterButtonClickHandler(handler) {
  [].slice.call(document.getElementsByClassName(BUTTON_CLASS)).forEach((el) => {
    el.addEventListener('click', handler, true);
  });
}

export function scrollToNav() {
  const el = document.getElementsByClassName(NAV_PLACEHOLDER_CLASS)[0];
  if (el) smoothScroll(el, 300);
}

export function updateDOMRegisteredState(isRegistered = true) {
  [].slice.call(document.querySelectorAll(`[${DATA_ATTRIB}]`)).forEach((el) => {
    const btnStateAttribute = el.getAttribute(DATA_ATTRIB);
    if (btnStateAttribute === 'basic') return;

    if (btnStateAttribute === 'registered' || btnStateAttribute === 'unregistered') {
      const showIfRegistered = (btnStateAttribute === 'registered');
      const shouldShow = showIfRegistered === isRegistered;
      el.classList.toggle(utilStyles.displayNone, !shouldShow);
    }
  });
}
