import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AuthButtons from './AuthButtons';
import BackButton from './BackButton';
import Categories from './Categories';
import Dialog from '../../reusable_components/Dialog';
import HacksterLogo from '../../reusable_components/HacksterLogo';
import Icon from '../../icon';
import LinkList from '../link_list';
import SocialIcons from '../social_icons';

import currentUserService from '../../../services/current_user';
import keenService from '../../../services/keen/main';
import errorHandler from '../../../services/error_handler';
import { getInObj } from '../../../utility/accessors';

import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';
import styles from './mobile_nav.css';

const NEWS_ADMIN_ROLES = ['admin', 'author', 'editor'];
const ARTICLES_LINK = { name: 'My articles', url: '/news/admin' };

const MobileLinkList = (props) => <LinkList className={styles.row} {...props} />;
const MobileSocialIcons = (props) => <SocialIcons className={styles.rowCenter} {...props} />;

const sectionComponents = {
  AuthButtons: AuthButtons,
  BackButton: BackButton,
  Categories: Categories,
  LinkList: MobileLinkList,
  SocialIcons: MobileSocialIcons,
};

class MobileNav extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPanel: {
        dropdown: null,
        panel: 'home',
      },
      currentUser: {},
      showMenu: false,
    };

    this.closeMenu = this.closeMenu.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.switchPanelOrDropdown = this.switchPanelOrDropdown.bind(this);
  }

  /**
   * Initializers
   */
  _initCurrentUser() {
    return currentUserService.getStoreAsync()
      .then((currentUser) => this.setState({ currentUser }))
      .catch((err) => errorHandler('MobileNav _initCurrentUser', err));
  }

  /**
   * Lifecycle
   */
  componentDidMount() {
    this._initCurrentUser();
  }

  /**
   * Methods
   */
  closeMenu() {
    this.setState({
      currentPanel: {
        dropdown: null,
        panel: 'home',
      },
      showMenu: false,
    });
  }

  openMenu() {
    this.setState({ showMenu: true });
    keenService.reportEvent({ eventName: 'Opened menu' }, { type: 'hamburgernav' });
  }

  switchPanelOrDropdown(e, menuType, value) {
    e.preventDefault();

    // If dropdown, assign new dropdown value or toggle existing (if no change).
    // If panel, assign new panel value and reset dropdown.
    if (menuType === 'dropdown') {
      this.setState((state) => ({
        currentPanel: {
          ...state.currentPanel,
          dropdown: state.currentPanel.dropdown === value ? null : value,
        },
      }));
    } else if (menuType === 'panel') {
      this.setState({
        currentPanel: {
          panel: value,
          dropdown: null,
        },
      });
    }
  }

  trackLinkClick({ delayRedirect = true, e, type, value }) {
    keenService.recordLinkClick({}, { location: 'hamburgernav', type, value }, delayRedirect, e);
  }

  /**
   * Helpers
   */
  _injectLink(items, link) {
    return items.slice(0, 5).concat([link]).concat(items.slice(5));
  }

  _shouldInjectNewsLink(section) {
    return (this.state.currentPanel.panel === 'home' && section.type === 'LinkList') && NEWS_ADMIN_ROLES.includes(getInObj(['news_role'], this.state.currentUser));
  }

  /**
   * Views
   */
  _renderSectionView(section, i) {
    const Section = sectionComponents[section.type];
    if (!Section) return null;

    const categoryProps = (Section === Categories) ? { currentDropdownKey: this.state.currentPanel.dropdown } : {};
    const injectedProps = this._shouldInjectNewsLink(section)
      ? { ...section.props, items: this._injectLink(section.props.items, ARTICLES_LINK) }
      : section.props;

    const props = {
      switchPanelOrDropdown: this.switchPanelOrDropdown,
      trackLinkClick: this.trackLinkClick,
      ...categoryProps,
      ...injectedProps,
    };

    return <Section key={i} {...props} />;
  }

  render() {
    const { panels } = this.props;
    const { currentPanel, showMenu } = this.state;

    return (
      <span id="mobile-nav-hamburger">
        <Icon className={typography.iconClickable} name="hamburger" onClick={this.openMenu} size="16" />
        <Dialog
          bodyClassName={utilStyles.bgWhite}
          className={styles.dialog}
          dismiss={this.closeMenu}
          dismissClassName={styles.dismiss}
          open={showMenu}
          overlayClassName={styles.overlay}
          wrapperClassName={styles.wrapper}
        >
          <div className={styles.rowHome}>
            <HacksterLogo asLink={false} location="mobile-nav" />
          </div>
          {panels[currentPanel.panel].sections.map((section, i) => this._renderSectionView(section, i))}
        </Dialog>
      </span>
    );
  }
}

MobileNav.propTypes = {
  panels: PropTypes.shape({
    home: PropTypes.shape({
      sections: PropTypes.arrayOf(
        PropTypes.shape({
          type: PropTypes.oneOf(Object.keys(sectionComponents)),
          props: PropTypes.object,
        }).isRequired,
      ).isRequired,
    }).isRequired,
    // Other panels will have the same shape, but are not required, and not explicitly specified here
  }).isRequired,
};

export default MobileNav;
