import React from 'react';
import PropTypes from 'prop-types';

import LazyImage from '../../reusable_components/LazyImage';
import UserRelationButton from '../../../server/user/relation_button';
import { labelOverride } from '../../../server/user/relation_button/config';

import { getInObj } from '../../../utility/accessors';
import { formatNumberWithCommas } from '../../../utility/formatters';

import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import utilStyles from '../../../styles/global_ui/util.css';

import styles from '../cards.css';

const _getLabelForItem = (item) => {
  switch (item.type) {
    case 'Community':
    case 'Platform':
      return labelOverride(`Join ${item.type}`);
    case 'TopicChannel':
      return labelOverride('Follow Topic');
    default:
      return labelOverride('Join Community');
  }
};

const _getNewBadge = (item) => {
  if (getInObj(['is_new'], item) !== true) return null;

  return (
    <div className={styles.newBadgeContainer}>
      <p className={styles.newBadge1}>NEW</p>
      <p className={styles.newBadge2}>to Hackster</p>
    </div>
  );
};

const ChannelCard = ({ classList, item, onClick, showBorder }) => (
  <div className={classList.wrapper || styles.wrapper}>
    <div
      className={`${styles.card} ${showBorder && utilStyles.secondaryBorder} ${
        styles.cardBody
      }`}
    >
      <div className={layout.flexJustifySpaceBetween}>
        {(item.avatar_url || item.logo_url) && (
          <div className={styles.icon}>
            <a href={item.url} onClick={onClick}>
              <LazyImage
                alt={item.name}
                captureScroll={true}
                className={item.avatar_dark_url ? utilStyles.hideFlexOnDarkMode : ''}
                fit="fill"
                queryParams={{ bg: 'ffffff' }}
                ratio="1:1"
                src={item.avatar_url || item.logo_url || ''}
                width={75}
              />
              {item.avatar_dark_url && (
                <LazyImage
                  alt={item.name}
                  captureScroll={true}
                  className={utilStyles.showFlexOnDarkMode}
                  fit="fill"
                  ratio="1:1"
                  src={item.avatar_dark_url}
                  width={75}
                />
              )}
            </a>
          </div>
        )}
        {_getNewBadge(item)}
      </div>
      <a
        className={`${styles.title} ${typography.bodyM} ${typography.linkCharcoal} ${layout.marginTop15} ${layout.marginBottom10}`}
        href={item.url}
        onClick={onClick}
      >
        {item.name}
      </a>
      <section className={typography.bodyS}>{item.pitch}</section>
      <div className={styles.spacer} />
      <div className={`${styles.stats} ${typography.bodyXS}`}>
        <span className={styles.stat}>
          {`${formatNumberWithCommas(item.members_count)} Members`}
        </span>
        <span className={styles.stat}>
          {`${formatNumberWithCommas(item.projects_count)} Projects`}
        </span>
      </div>
      <UserRelationButton
        config={{ label: _getLabelForItem(item) }}
        id={item.id}
        type="channel_sm"
      />
    </div>
  </div>
);

ChannelCard.propTypes = {
  classList: PropTypes.shape({ wrapper: PropTypes.string }),
  item: PropTypes.shape({
    avatar_url: PropTypes.string,
    id: PropTypes.number,
    is_new: PropTypes.bool,
    logo_url: PropTypes.string,
    members_count: PropTypes.number,
    name: PropTypes.string,
    pitch: PropTypes.string,
    projects_count: PropTypes.number,
    type: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func,
  showBorder: PropTypes.bool,
};

ChannelCard.defaultProps = {
  classList: {},
  onClick: () => {},
  showBorder: true,
};

export default ChannelCard;
