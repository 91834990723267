import React from 'react';
import PropTypes from 'prop-types';

import { pluralize } from '../../../../utility/formatters';

import layout from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import styles from './idea_form.css';

const FAQ = ({ hardware, supportEmail }) => (
  <div className={styles.faqContainer}>
    <p className={`${typography.bodyM} ${typography.bold} ${layout.marginBottom10}`}>Frequently Asked Questions</p>

    <div className={`${styles.faqBox} ${typography.bodyM}`}>
      <div className={layout.marginBottom30}>
        <strong>What kind of idea is more likely to win free hardware?</strong>
        <p className={layout.marginTop10}>We encourage creative ideas. For instance, pet feeders and home automation systems are very common nowadays and less creative. We also encourage realistic ideas. While solving world hunger is a great goal, it may not be realistic.</p>
      </div>

      <div className={layout.marginBottom30}>
        <strong>Do I have to submit a project entry if I receive free hardware?</strong>
        <p className={layout.marginTop10}>Yes, all free hardware recipients must submit a project entry by the contest deadline. Free hardware recipients who do not submit a project entry will become ineligible to receive free hardware in future giveaways.</p>
      </div>

      <div className={layout.marginBottom30}>
        <strong>What happens if I receive free hardware and do not submit a project entry?</strong>
        <p className={layout.marginTop10}>Participants who receive free hardware and do not submit a project may be ineligible for future giveaways unless the hardware is returned.</p>
        <p className={layout.marginTop10}>
          <span>If you receive free hardware and become unable to submit a project, please contact us at </span>
          <a href={`mailto:${supportEmail}`} rel="noopener nofollow noreferrer" target="_blank">{supportEmail}</a>
          <span> and let us know as soon as possible. We may choose to arrange for the hardware to be shipped to another participant.</span>
        </p>
      </div>

      <div className={layout.marginBottom30}>
        <strong>{`How many ${hardware.name} are available?`}</strong>
        <p className={layout.marginTop10}>{`${hardware.remaining} ${pluralize(hardware.name, hardware.remaining)} are available.`}</p>
      </div>

      <div>
        <strong>How many ideas can I submit?</strong>
        <p className={layout.marginTop10}>Each user is allowed to submit one idea.</p>
      </div>
    </div>
  </div>
);

FAQ.propTypes = {
  hardware: PropTypes.shape({
    name: PropTypes.string.isRequired,
    remaining: PropTypes.number.isRequired,
  }).isRequired,
  supportEmail: PropTypes.string.isRequired,
};

export default FAQ;
