import React from 'react';
import PropTypes from 'prop-types';

import AdminMenu from './../admin_menu';
import BasicImage from '../../../client/image/BasicImage';
import NavBar from './NavBar';
import UserRelationButton from '../../../server/user/relation_button';

import styles from './header.css';
import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import util from '../../../styles/global_ui/util.css';

const isWebsiteLinkAvailable = ({ websites }) => !!websites && !!websites.website_link;

const shouldRenderWebsiteLink = function(model) {
  const hasCTA = model.call_to_action && model.call_to_action.url;

  return hasCTA // Do not render website link if its the same as CTA.
    ? isWebsiteLinkAvailable(model) && model.websites.website_link !== model.call_to_action.url
    : isWebsiteLinkAvailable(model);
};

const Header = ({
  admin_menu,
  currentPath,
  initPath,
  initialized,
  isWorking,
  model,
  newFeatureMsg,
  routes,
  transition,
  userPermissions,
}) => (
  <div>
    {newFeatureMsg()}

    <div className={`${layout.container} ${styles.root}`} id="reactSceneHeader">
      <div className={`${layout.wrapper1170} ${styles.wrapper}`}>
        <div className={styles.header}>
          <div className={styles.contentWrapper}>
            {(model.logo_url || model.avatar_url) && (
              <div className={styles.avatar}>
                <BasicImage
                  className={`${
                    model.avatar_dark_url ? util.hideOnDarkMode : ''
                  }`}
                  fit="fillmax"
                  queryParams={{ bg: 'fff' }}
                  ratio="1:1"
                  src={model.avatar_url || model.logo_url}
                  width={75}
                />
                {model.avatar_dark_url && (
                  <BasicImage
                    className={util.showOnDarkMode}
                    fit="fillmax"
                    ratio="1:1"
                    src={model.avatar_dark_url}
                    width={75}
                  />
                )}
              </div>
            )}

            <div className={styles.content}>
              <h1 className={`${typography.h2} ${styles.name}`}>
                {model.name}
              </h1>
              {model.about && (
                <div className={`${typography.bodyM} ${styles.body}`}>
                  {model.about}
                </div>
              )}
              {model.call_to_action
              && model.call_to_action.label
              && model.call_to_action.url && (
                <a
                  className={`${typography.linkBlue} ${typography.bodyS} ${typography.bold} ${styles.callToAction}`}
                  href={model.call_to_action.url}
                  rel="noopener nofollow noreferrer"
                  target="_blank"
                >
                  {model.call_to_action.label}
                </a>
              )}

              {shouldRenderWebsiteLink(model) && (
                <a
                  className={`${typography.linkPebble} ${typography.bodyS} ${typography.bold}`}
                  href={model.websites.website_link}
                  rel="noopener nofollow noreferrer"
                  target="_blank"
                >
                  Visit website
                </a>
              )}
            </div>
          </div>

          <div className={styles.actions}>
            {initialized && userPermissions.channelRole !== 'admin' && (
              <UserRelationButton id={model.id} type="channel_sm" />
            )}
            <AdminMenu
              canManage={userPermissions.canManage}
              menu={admin_menu}
            />
          </div>
        </div>

        <NavBar
          currentPath={currentPath}
          externalLinkRoot={model.user_name}
          initPath={initPath}
          isWorking={isWorking}
          routes={routes}
          transition={transition}
        />
      </div>
    </div>
  </div>
);

Header.propTypes = {
  admin_menu: PropTypes.array.isRequired,
  currentPath: PropTypes.string.isRequired,
  initPath: PropTypes.string.isRequired,
  isWorking: PropTypes.bool.isRequired,
  model: PropTypes.shape({
    about: PropTypes.string,
    call_to_action: PropTypes.shape({
      label: PropTypes.string,
      url: PropTypes.string,
    }),
    id: PropTypes.number.isRequired,
    logo_url: PropTypes.string,
    name: PropTypes.string,
    websites: PropTypes.shape({ website_link: PropTypes.string }),
    user_name: PropTypes.string.isRequired,
  }).isRequired,
  newFeatureMsg: PropTypes.func,
  routes: PropTypes.array.isRequired,
  transition: PropTypes.func.isRequired,
  userPermissions: PropTypes.shape({ canManage: PropTypes.bool.isRequired }).isRequired,
};

Header.defaultProps = { newFeatureMsg: () => {} };

export default Header;
