import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Breadcrumb from '../../client/nav_components/breadcrumb';
import Button from '../../client/buttons/base';
import ProgressBar from '../../client/reusable_components/ProgressBar';
import Step from './Step';

import { graphMutate } from '../../requests/graphql';
import errorHandler from '../../services/error_handler';
import { InfoPageOptions } from './types';
import { windowLocationRedirect } from '../../services/window';

import HacksterLogo from '../../client/reusable_components/HacksterLogo';

import layout from '../../styles/global_ui/layout.css';
import typography from '../../styles/global_ui/typography.css';
import util from '../../styles/global_ui/util.css';
import style from './layout.css';

import { ONBOARDING_STEPS, ONBOARDING_STEPS_ENUM_MAP, ONBOARDING_STEPS_ORDER_MAP, ONBOARDING_STEPS_VALUE_MAP } from './constants';

const c = {
  footerContainer: ({ fixedFooter }) => `${fixedFooter ? style.fixed : layout.paddingTop15} ${layout.fullWidth} ${util.bgWhite} ${style.footer}`,
  footer: ({ fixedFooter }) => `${fixedFooter ? layout.wrapper1170 : ''} ${layout.marginAuto} ${layout.fullWidth} ${layout.flexCenterItems} ${layout.flexJustifySpaceBetween}`,
  footerRight: `${layout.flexJustifyEnd} ${layout.gutter15} ${style.footerRight}`,
  finishLater: `${typography.linkPebble} ${typography.bold}`,
};

function getParams(history) {
  if (!history.location?.search) return {};

  return Object.fromEntries(new URLSearchParams(history.location.search));
}

const OnboardingLayout = ({
  canContinue,
  currentPath,
  history,
  options,
  progress,
  selectedActivities,
  selectedCommunities,
  selectedCountry,
  selectedIndustry,
  selectedJob,
  selectedPlatforms,
  selectedProducts,
  selectedSkillLevel,
  selectedTopics,
  setSelectedActivities,
  setSelectedCommunities,
  setSelectedCountry,
  setSelectedIndustry,
  setSelectedJob,
  setSelectedPlatforms,
  setSelectedProducts,
  setSelectedSkillLevel,
  setSelectedTopics,
  showGreeting,
  userId,
}) => {
  const hasBackBtn = currentPath !== 'activities';
  const fixedFooter = currentPath === 'interests';
  const nextStepIsDashboard = currentPath === 'info';
  const [onboardingProgress, setOnboardingProgress] = useState(progress);

  const steps = ONBOARDING_STEPS.map((step, index) => {
    const lastStepCompleted = onboardingProgress[onboardingProgress.length - 1];
    const canAccess = onboardingProgress.includes(step)
      || ((ONBOARDING_STEPS_ORDER_MAP[lastStepCompleted] + 1) >= ONBOARDING_STEPS_ORDER_MAP[step]);
    const currentIndex = ONBOARDING_STEPS.indexOf(currentPath);
    const isComplete = index < currentIndex;
    const isDisabled = onboardingProgress.length === 0 || !canAccess;

    const onClick = (e) => {
      e.preventDefault();
      if (isDisabled) return;

      history.push(step);
    };

    return {
      isActive: step === currentPath,
      isComplete,
      isDisabled,
      isLast: index >= ONBOARDING_STEPS.length - 1,
      href: `/onboarding/${step}`,
      name: step,
      onClick,
    };
  });

  const getDesiredStepRoute = (currentPath, num) => ONBOARDING_STEPS[ONBOARDING_STEPS.indexOf(currentPath) + num];

  const updateOnboardingProgress = (step) => (
    graphMutate({ t: 'update_user_onboarding_progress_temp' }, { step })
      .then(({ user: { onboarding_progress_temp } }) => {
        setOnboardingProgress(onboarding_progress_temp.map((step) => ONBOARDING_STEPS_VALUE_MAP[step]));
      })
      .catch((err) => {
        errorHandler('UserOnboardingPage updateOnboardingProgress Error:', err);
      })
  );

  const handleContinueClick = async () => {
    if (!canContinue) return;

    if (ONBOARDING_STEPS.includes(currentPath) && !onboardingProgress.includes(currentPath)) {
      await updateOnboardingProgress(ONBOARDING_STEPS_ENUM_MAP[currentPath]);
    }

    nextStepIsDashboard
      ? windowLocationRedirect('/feed')
      : history.push(getDesiredStepRoute(currentPath, 1));
  };

  const { filter: defaultFilter } = getParams(history);

  return (
    <div className={style.page}>
      <div
        className={`${layout.wrapper1170} ${layout.flexColumnCenterCenter} ${layout.gutter45} ${layout.marginAuto} ${layout.fullWidth} ${style.layoutWrapper}`}
      >
        <nav className={`${layout.fullWidth} ${layout.paddingTop15} ${layout.gutter30} ${style.nav}`}>
          <HacksterLogo location="onboarding-nav" />
          <ProgressBar
            classList={{ root: style.maxWidth240 }}
            iconSize={20}
            steps={steps}
          />
        </nav>
        <Step
          // TODO: Didn't do at this stage, but prob can restructure props to be a bit more organized.
          currentPath={currentPath}
          defaultFilter={defaultFilter}
          options={options}
          selectedActivities={selectedActivities}
          selectedCommunities={selectedCommunities}
          selectedCountry={selectedCountry}
          selectedIndustry={selectedIndustry}
          selectedJob={selectedJob}
          selectedPlatforms={selectedPlatforms}
          selectedProducts={selectedProducts}
          selectedSkillLevel={selectedSkillLevel}
          selectedTopics={selectedTopics}
          setSelectedActivities={setSelectedActivities}
          setSelectedCommunities={setSelectedCommunities}
          setSelectedCountry={setSelectedCountry}
          setSelectedIndustry={setSelectedIndustry}
          setSelectedJob={setSelectedJob}
          setSelectedPlatforms={setSelectedPlatforms}
          setSelectedProducts={setSelectedProducts}
          setSelectedSkillLevel={setSelectedSkillLevel}
          setSelectedTopics={setSelectedTopics}
          showGreeting={showGreeting}
          userId={userId}
        />
        <footer className={c.footerContainer({ fixedFooter })}>
          <div className={c.footer({ fixedFooter })}>
            {hasBackBtn && (
              <Breadcrumb
                classList={{ root: layout.marginBottom0, iconLeft: layout.marginRight5 }}
                color="Pebble"
                href="/onboarding"
                onClick={(e) => {
                  e.preventDefault(); // Prevent page reload.
                  history.push(getDesiredStepRoute(currentPath, -1));
                }}
                size="S"
                text="Back"
              />
            )}
            <div className={c.footerRight}>
              <div className={layout.flexCenterItems}>
                <a className={c.finishLater} href="/feed">
                  Finish later
                </a>
              </div>
              <Button
                disabled={!canContinue}
                onClick={handleContinueClick}
              >
                {nextStepIsDashboard ? 'Show me my feed' : 'Continue'}
              </Button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

OnboardingLayout.propTypes = {
  canContinue: PropTypes.bool.isRequired,
  currentPath: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  options: PropTypes.shape({
    activities: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    interests: PropTypes.shape({
      communities: PropTypes.arrayOf(PropTypes.shape({
        avatar_url: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired).isRequired,
      platforms: PropTypes.arrayOf(PropTypes.shape({
        avatar_url: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired).isRequired,
      products: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        image_url: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired).isRequired,
      topics: PropTypes.arrayOf(PropTypes.shape({
        avatar_url: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired).isRequired,
    }).isRequired,
    info: PropTypes.shape(InfoPageOptions).isRequired,
  }).isRequired,
  progress: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedActivities: PropTypes.arrayOf(PropTypes.string),
  selectedCommunities: PropTypes.arrayOf(PropTypes.number),
  selectedCountry: PropTypes.string,
  selectedIndustry: PropTypes.string,
  selectedJob: PropTypes.string,
  selectedPlatforms: PropTypes.arrayOf(PropTypes.number),
  selectedProducts: PropTypes.arrayOf(PropTypes.number),
  selectedSkillLevel: PropTypes.string,
  selectedTopics: PropTypes.arrayOf(PropTypes.number),
  setSelectedActivities: PropTypes.func.isRequired,
  setSelectedCommunities: PropTypes.func.isRequired,
  setSelectedCountry: PropTypes.func.isRequired,
  setSelectedIndustry: PropTypes.func.isRequired,
  setSelectedJob: PropTypes.func.isRequired,
  setSelectedPlatforms: PropTypes.func.isRequired,
  setSelectedProducts: PropTypes.func.isRequired,
  setSelectedSkillLevel: PropTypes.func.isRequired,
  setSelectedTopics: PropTypes.func.isRequired,
  showGreeting: PropTypes.bool.isRequired,
  userId: PropTypes.number.isRequired,
};

OnboardingLayout.defaultProps = {
  selectedActivities: [],
  selectedCommunities: [],
  selectedCountry: null,
  selectedIndustry: null,
  selectedJob: null,
  selectedPlatforms: [],
  selectedProducts: [],
  selectedSkillLevel: null,
  selectedTopics: [],
};

export default OnboardingLayout;
