/**
 * Components exported here are what Hacksternova uses to match the component by key.
 */
import ActiveFeedbackSurvey from '../client/reusable_components/ActiveFeedbackSurvey';
import Business from '../scenes/business';
import CategoryTagDropdown from '../server/projects/category_tag_dropdown';
import ChannelPage from '../scenes/channel_page';
import ChannelsPage from '../scenes/channels_page';
import ContentTypeSelector from '../server/projects/content_type_selector/app';
import ContestBriefPage from '../scenes/contest_page/brief';
import ContestEntrySubmitForm from '../scenes/contest_page/entry/submit_form';
import ContestIdeaPage from '../scenes/contest_page/idea';
import ContestIdeaForm from '../scenes/contest_page/idea/idea_form';
import ContestIdeasPage from '../scenes/contest_page/ideas';
import ContestsPage from '../scenes/contests_page';
import Dashboard from '../client/user/edit/dashboard';
import EventsHomePage from '../scenes/events/events_home_page';
import FeedbackSurvey from '../client/reusable_components/FeedbackSurvey';
import FeedPage from '../scenes/feed_page';
import HomePage from '../scenes/home_page';
import ImageCarousel from '../server/projects/image_carousel';
import MembershipPage from '../scenes/tiers/membership_page';
import HacksterLogo from '../client/reusable_components/HacksterLogo';
import MobileNav from '../client/nav/mobile_nav';
import ModularAd from '../client/ads/modular_ad/ModularAd';
import NewsArticlePage from '../scenes/news/article_page';
import NewsHomePage from '../scenes/news/home_page';
import NotificationDropdown from '../server/layouts/notification_dropdown';
import Projects from '../scenes/projects_page';
import ProsAboutPage from '../scenes/tiers/pros_page';
import Search from '../server/search';
import SearchInput from '../server/search/input';
import TopNav from '../client/nav/top_nav';
import UserOnboardingPage from '../scenes/user_onboarding_page';
import UserProfile from '../server/user/profile';
import UserRelationButton from '../server/user/relation_button';
import VideoPage from '../scenes/video_page';
import VideosPage from '../scenes/videos_page';
import WhitelabelPage from '../scenes/whitelabel_page';
import WhitelabelSearchInput from '../server/search/whitelabel_input';

export default {
  ActiveFeedbackSurvey,
  Business,
  CategoryTagDropdown,
  ChannelPage,
  ChannelsPage,
  ContentTypeSelector,
  ContestBriefPage,
  ContestEntrySubmitForm,
  ContestIdeaForm,
  ContestIdeaPage,
  ContestIdeasPage,
  ContestsPage,
  Dashboard,
  EventsHomePage,
  FeedPage,
  FeedbackSurvey,
  HomePage,
  ImageCarousel,
  HacksterLogo,
  MobileNav,
  MembershipPage,
  ModularAd,
  NewsArticlePage,
  NewsHomePage,
  NotificationDropdown,
  Projects,
  ProsAboutPage,
  Search,
  SearchInput,
  TopNav,
  UserOnboardingPage,
  UserProfile,
  UserRelationButton,
  VideoPage,
  VideosPage,
  WhitelabelPage,
  WhitelabelSearchInput,
};
