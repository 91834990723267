import React from 'react';
import PropTypes from 'prop-types';

import BannerSmallContestCard from '../../client/cards/contest_card/BannerSmallContestCard';
import BannerSmallContestCardDummy from '../../client/cards/contest_card/BannerSmallContestCardDummy';
import LazyComponent from '../../client/wrappers/lazy_component';
import PaginatedList from '../../client/wrappers/paginated_list';

import grid from '../../styles/global_ui/grid.css';
import layout from '../../styles/global_ui/layout.css';
import typography from '../../styles/global_ui/typography.css';
import utilStyles from '../../styles/global_ui/util.css';
import styles from './contests_page.css';

import { MEMBERSHIP_TIERS } from './enum.js';

const LoaderView = () => [...Array(8).keys()].map((i) => (
  <div key={i} className={`${grid.cell} ${styles.bannerCardWrapper}`}>
    <BannerSmallContestCardDummy />
  </div>
));

const PastContestsList = ({ contests, getPaginatedPastContests, isBusy, metadata }) => (
  <div className={`${layout.flexJustifyCenter} ${layout.paddingTop30} ${layout.paddingBottom45} ${layout.paddingLeft15} ${layout.paddingRight15} ${styles.bannerListRoot}`}>
    <div className={`${layout.wrapper1170} ${layout.marginTop30}`}>
      <h3 className={`${layout.marginBottom22} ${typography.h3}`}>Past</h3>

      <LazyComponent alwaysRenderChildren={true} onReveal={getPaginatedPastContests} verticalOffset={0}>
        <div className={layout.marginBottom10}>
          <PaginatedList
            buttonText="Show more"
            disabled={isBusy}
            onPaginatorClick={getPaginatedPastContests}
            paginator={{
              currentPage: metadata.current_page,
              nextPage: metadata.next_page,
              prevPage: metadata.prev_page,
            }}
            type="button"
          >
            <div className={`${grid.grid} ${layout.flexJustifySpaceBetween} ${styles.bannerCardListWrapper}`}>
              {contests.map((contest) => (
                <div key={contest.id} className={`${grid.cell} ${utilStyles.boxShadow3} ${utilStyles.borderRadius} ${styles.bannerCardWrapper}`}>
                  <BannerSmallContestCard contest={contest} />
                </div>
              ))}
              {isBusy && <LoaderView />}
            </div>
          </PaginatedList>
        </div>
      </LazyComponent>
    </div>
  </div>
);

PastContestsList.propTypes = {
  contests: PropTypes.arrayOf(PropTypes.shape({
    cover_image: PropTypes.shape({ url: PropTypes.string.isRequired }).isRequired,
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    required_user_tier: PropTypes.oneOf(MEMBERSHIP_TIERS).isRequired,
    status: PropTypes.string.isRequired,
    sponsors: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      url: PropTypes.string,
    })).isRequired,
    url: PropTypes.string.isRequired,
  })),
  getPaginatedPastContests: PropTypes.func.isRequired,
  isBusy: PropTypes.bool.isRequired,
  metadata: PropTypes.shape({
    current_page: PropTypes.number.isRequired,
    next_page: PropTypes.number,
    per_page: PropTypes.number.isRequired,
    prev_page: PropTypes.number,
  }).isRequired,
};

export default PastContestsList;
