import React from 'react';
import PropTypes from 'prop-types';

// import AnchorButton from '../../client/buttons/anchor';
import ActiveContestCard from '../../client/cards/contest_card/ActiveContestCard';
import { MEMBERSHIP_TIERS } from './enum';
import ContestCard from '../../client/cards/contest_card';
import HorizontalScrollBox from '../../client/wrappers/horizontal_scroll_box';

import layout from '../../styles/global_ui/layout.css';
import typography from '../../styles/global_ui/typography.css';
import styles from './contests_page.css';

const ActiveContestsSection = ({
  challengeContests,
  competitionContests,
}) => (
  <>
    {competitionContests.length > 0 && (
      <div className={layout.marginBottom45}>
        <h2 className={`${layout.marginBottom0} ${typography.h2Responsive}`}>
          Competitions
        </h2>
        <p>
          May the best build win! Compete against others for hardware, prizes,
          and fame.
        </p>
        {competitionContests.map((contest) => (
          <ActiveContestCard
            key={contest.id}
            contest={contest}
          />
        ))}
      </div>
    )}

    {challengeContests.length > 0 && (
      <div className={`${layout.wrapper1170}`}>
        <div className={`${layout.flex} ${layout.gutter5}`}>
          <h2 className={`${layout.marginBottom0} ${typography.h2Responsive}`}>
            Challenges
          </h2>
          <span
            className={`${typography.bodyM} ${typography.rose} ${layout.marginTop0}`}
          >
            Beta
          </span>
        </div>
        <div
          className={`${layout.flexJustifySpaceBetween} ${layout.flexEndItems} ${layout.gutter10} ${layout.marginBottom10}`}
        >
          <p className={layout.marginBottom0}>
            Unique problems looking for innovators to tackle. Limited slots for
            serious contenders. Exclusive awards for selected proposals.
          </p>
          {/*  TODO: build out a page for all challenges.
          <AnchorButton href="/challenges" size="md">
            View All
          </AnchorButton> */}
        </div>
        <HorizontalScrollBox
          classList={{ wrapper: `${layout.marginBottom30}` }}
        >
          {challengeContests.map((contest) => (
            <ContestCard
              key={contest.id}
              classList={{ wrapper: styles.recentCard }}
              config={{ btnSize: 'md' }}
              item={contest}
            />
          ))}
          {challengeContests.length < 4 && (
            <div className={styles.moreComing}>
              <span className={typography.h3}>
                New Challenges are released often.
              </span>
              <span className={typography.h3}>More coming soon!</span>
            </div>
          )}
        </HorizontalScrollBox>
      </div>
    )}
  </>
);

ActiveContestsSection.propTypes = {
  challengeContests: PropTypes.arrayOf(
    PropTypes.shape({
      cover_image: PropTypes.shape({ url: PropTypes.string.isRequired })
        .isRequired,
      disable_projects_tab: PropTypes.bool.isRequired,
      end_date_in_pdt: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      is_registration_open: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      required_user_tier: PropTypes.oneOf(MEMBERSHIP_TIERS).isRequired,
      prize: PropTypes.shape({
        cash_value: PropTypes.number.isRequired,
        icon_urls: PropTypes.shape({
          x1: PropTypes.string.isRequired,
          x2: PropTypes.string.isRequired,
        }).isRequired,
        image: PropTypes.shape({ url: PropTypes.string.isRequired }),
        name: PropTypes.string.isRequired,
      }).isRequired,
      sponsors: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          url: PropTypes.string,
        }),
      ).isRequired,
      start_date_in_pdt: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      submissions_url: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  competitionContests: PropTypes.arrayOf(
    PropTypes.shape({
      cover_image: PropTypes.shape({ url: PropTypes.string.isRequired })
        .isRequired,
      disable_projects_tab: PropTypes.bool.isRequired,
      end_date_in_pdt: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      is_registration_open: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired,
      required_user_tier: PropTypes.oneOf(MEMBERSHIP_TIERS).isRequired,
      prize: PropTypes.shape({
        cash_value: PropTypes.number.isRequired,
        icon_urls: PropTypes.shape({
          x1: PropTypes.string.isRequired,
          x2: PropTypes.string.isRequired,
        }).isRequired,
        image: PropTypes.shape({ url: PropTypes.string.isRequired }),
        name: PropTypes.string.isRequired,
      }).isRequired,
      sponsors: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          url: PropTypes.string,
        }),
      ).isRequired,
      start_date_in_pdt: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      submissions_url: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default ActiveContestsSection;
