import React from 'react';
import PropTypes from 'prop-types';

import ProjectThumbs from '../../../content_locking/project_thumbs';

import { DEFAULT, PROJECT_LOCK_SCREEN } from '../../constants';
import { getViewedProjects } from '../../../content_locking/storageHelpers';

import layoutStyles from '../../../../styles/global_ui/layout.css';
import typography from '../../../../styles/global_ui/typography.css';
import util from '../../../../styles/global_ui/util.css';
import styles from './cta.css';

const text = {
  DEFAULT: {
    body: 'Create your account to explore thousands of projects, build your skills, and discover new products and technologies.',
    title: 'Join the world’s best hardware engineers.',
  },
  PROJECT_LOCK_SCREEN: {
    body: "You've reached the end of your Hackster preview. Sign up to continue reading this project and join the world's largest hardware engineering community.",
    title: 'Join Hackster to view unlimited everything.',
  },
};

const imageUrls = {
  x1Light: 'https://hacksterio.s3.us-east-2.amazonaws.com/static/misc/signup/spaceman-signup-light.png',
  x2Light: 'https://hacksterio.s3.us-east-2.amazonaws.com/static/misc/signup/spaceman2x-signup-light.png',
  x1Dark: 'https://hacksterio.s3.us-east-2.amazonaws.com/static/misc/signup/spaceman-signup-dark.png',
  x2Dark: 'https://hacksterio.s3.us-east-2.amazonaws.com/static/misc/signup/spaceman2x-signup-dark.png',
};

const _getCTAStyles = (ctaVersion) => _shouldShowProjectThumbs(ctaVersion) ? styles.ctaLockedProject : styles.cta;

function _shouldShowProjectThumbs(ctaVersion) {
  return ctaVersion === PROJECT_LOCK_SCREEN;
}

/**
 * Views
 */
const _getCTA = ({ ctaVersion, whitelabelName }) => (
  <div className={_getCTAStyles(ctaVersion)}>
    {_shouldShowProjectThumbs(ctaVersion) && _getProjectThumbs()}
    <h1 className={styles.ctaHeader}>{text[ctaVersion].title}</h1>
    {whitelabelName && (
      <h2 className={styles.ctaSubHeader}>
        {`${whitelabelName} is powered by Hackster`}
      </h2>
    )}
    <p className={typography.bodyL}>{text[ctaVersion].body}</p>
    <img
      className={`${styles.ctaImg} ${util.hideOnDarkMode}`}
      src={imageUrls.x1Light}
      srcSet={`${imageUrls.x1Light} 1x, ${imageUrls.x2Light} 2x`}
    />
    <img
      className={`${styles.ctaImg} ${util.showOnDarkMode}`}
      src={imageUrls.x1Dark}
      srcSet={`${imageUrls.x1Dark} 1x, ${imageUrls.x2Dark} 2x`}
    />
  </div>
);

const _getProjectThumbs = () => (
  <div className={layoutStyles.marginBottom15}>
    <ProjectThumbs {...getViewedProjects()} />
  </div>
);

const CTA = (props) => _getCTA(props);

CTA.propTypes = {
  ctaVersion: PropTypes.string,
  whitelabelName: PropTypes.string,
};

CTA.defaultProps = {
  ctaVersion: DEFAULT,
  whitelabelName: null,
};

export default CTA;
