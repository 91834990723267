import React from 'react';
import PropTypes from 'prop-types';

import LazyImage from '../../reusable_components/LazyImage';

import cardStyles from '../cards.css';
import layout from '../../../styles/global_ui/layout.css';
import typography from '../../../styles/global_ui/typography.css';
import util from '../../../styles/global_ui/util.css';
import styles from './simple_logo_card.css';

const handleClick = (e, overrideTracking, record, i) => {
  if (overrideTracking && typeof overrideTracking === 'function') {
    overrideTracking(
      e,
      { external: record.url, internal: '' },
      { id: record.id, index: i, type: 'logo_card' },
    );
  }
};

const LogoCard = ({ classList, i, overrideTracking, record }) => (
  <div className={classList.wrapper || styles.wrapper}>
    <div className={styles.card}>
      <div className={styles.image}>
        <a
          href={record.url}
          onClick={(e) => handleClick(e, overrideTracking, record, i)}
        >
          <LazyImage
            alt={record.name}
            captureScroll={true}
            className={
              record.avatar_dark_url ? util.hideFlexOnDarkMode : ''
            }
            fit="fillmax"
            queryParams={{ bg: 'fff' }}
            ratio="1:1"
            src={record.avatar_url || record.logo_url || ''}
            width={108}
          />
          {record.avatar_dark_url && (
            <LazyImage
              alt={record.name}
              captureScroll={true}
              className={util.showFlexOnDarkMode}
              fit="fillmax"
              ratio="1:1"
              src={record.avatar_dark_url}
              width={108}
            />
          )}
        </a>
      </div>
      <a
        className={`${cardStyles.title} ${typography.bodyM} ${typography.linkCharcoal} ${layout.marginTop5}`}
        href={record.url}
        onClick={(e) => handleClick(e, overrideTracking, record, i)}
      >
        {record.name}
      </a>
    </div>
  </div>
);

LogoCard.propTypes = {
  classList: PropTypes.shape({ wrapper: PropTypes.string }),
  i: PropTypes.number,
  overrideTracking: PropTypes.func,
  record: PropTypes.shape({
    avatar_url: PropTypes.string,
    id: PropTypes.number,
    logo_url: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

LogoCard.defaultProps = {
  classList: {},
  i: null,
  overrideTracking: null,
};

export default LogoCard;
